import React from "react";
import { styled } from "@mui/material/styles";
import { SubSideBar, ContentHeader } from "../../components";
const HelpDesk = ({ children }) => {
  const RootStyle = styled("div")(({ theme }) => ({
    display: "flex",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  }));
  const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  }));
  return (
    <RootStyle>
      <SubSideBar />
      <div style={{ width: "100%" }} className="help-desk-container">
        <ContentHeader />
        <MainStyle>{children}</MainStyle>
      </div>{" "}
    </RootStyle>
  );
};

export default HelpDesk;
