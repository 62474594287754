import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Message from "./Message";
import { Messaging as MessageTyping } from "react-cssfx-loading";
import { useEffect } from "react";
import { addEvent } from "../../../../socket/socket";
import InfiniteScroll from "react-infinite-scroll-component";
import { conversationMessages } from "../../../../slices/messages";
import Loader1 from "../../../../components/Loader1";
import { Avatar, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getArchivedMessages } from "../../../../slices/archivedMessages";
import { isLabelMatched } from "../../../../utilities/isLabelMatched";
import { nanoid } from "nanoid";

const Messages = ({ user, state, setTodo, archived, firstMessage }) => {
  const { messagesStored, status, page, hasNextPage } = useSelector(
    (state) => state.messages
  );

  const {
    archivedMessagesStored,
    archivedMessagesStatus,
    pageCounter,
    hasNextPageForArchivedMessages,
  } = useSelector((state) => state.archivedMessages);
  const { statusClientConversation, clientConversationStored } = useSelector(
    (state) => state.conversations
  );

  const Messages = !clientConversationStored?.meta
    ? archived
      ? archivedMessagesStored
      : messagesStored
    : (archived ? archivedMessagesStored : messagesStored)?.flatMap(
        (message) => {
          if (
            message?.from === "BOT" &&
            isLabelMatched(
              clientConversationStored?.meta,
              message?.question?.label
            )
          ) {
            return [
              message,
              {
                _id: nanoid(),
                content:
                  clientConversationStored?.meta[`${message?.question?.label}`],
                createdAt: Date.now(),
                conversation: message?.conversation,
                from: "CLIENT",
                fromMeta: true,
              },
            ];
          } else {
            return message;
          }
        }
      );

  const { statusClientArchivedConversation } = useSelector(
    (state) => state.archivedConversation
  );
  const { isShortcutFilterCard, file } = useSelector(
    (state) => state.newMessage
  );

  const dispatch = useDispatch();
  const [isTyping, setIsTyping] = useState(true);
  const [internalState, setInternalState] = useState("idle");
  const fetchConversationMessages = () => {
    setInternalState("pending");
    (archived
      ? dispatch(
          getArchivedMessages({
            websiteID: user?.websiteID,
            conversationID: Messages[0]?.conversation,
            page: pageCounter,
          })
        )
      : dispatch(
          conversationMessages({
            conversationId: Messages[0]?.conversation,
            websiteId: user?.websiteID,
            page: page,
          })
        )
    ).then((res) => !res?.error && setInternalState("succeeded"));
  };
  useEffect(() => {
    addEvent("IS_TYPING_FROM_CLIENT", (data) => {
      if (data?.isTyping) {
        setIsTyping(data.conversationId);
      } else {
        setIsTyping(null);
      }
    });
  }, [isTyping !== undefined]);

  useEffect(() => {
    let timer;
    timer = setTimeout(function () {
      setIsTyping(null);
    }, 20000);
    return () => {
      clearTimeout(timer);
    };
  }, [isTyping]);

  const { t } = useTranslation();
  if (firstMessage) {
    return (
      <div
        id="scrollableDiv"
        dir="ltr"
        style={{
          overflow: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          flexBasis: "88%",
          justifyContent: "space-between",
          height:
            isShortcutFilterCard && file
              ? "22vh"
              : isShortcutFilterCard || file
              ? "44vh"
              : "57vh",
          padding: "7px",
        }}
      >
        <InfiniteScroll
          className="discussion-content"
          dataLength={Messages.length}
          next={fetchConversationMessages}
          style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
          inverse={true}
          hasMore={hasNextPage === true}
          scrollableTarget="scrollableDiv"
        >
          {Messages.map((item) => (
            <Message
              message={item}
              key={item._id}
              user={user}
              state={state}
              uniqueKey={item._id}
              setTodo={setTodo}
              firstMessage={firstMessage}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  }

  if (
    (archived ? statusClientArchivedConversation : statusClientConversation) ===
    "idle"
  ) {
    return (
      <span className="open-conversation-style">
        {state ? (
          <React.Fragment>
            <Loader1 />
          </React.Fragment>
        ) : (
          t("Open a conversation")
        )}
      </span>
    );
  }
  if (
    (archived ? statusClientArchivedConversation : statusClientConversation) ===
    "loading"
  ) {
    return (
      <div className="circular-progress-messenger">
        <CircularProgress
          sx={{
            width: "60px",
            height: "60px",
            display: "flex",
            alignSelf: "center",
            color: "#6932FA",
          }}
        />
      </div>
    );
  }
  return (archived ? archivedMessagesStatus : status) === `succeeded` &&
    (!archived
      ? statusClientConversation === `succeeded`
      : statusClientArchivedConversation) ? (
    <React.Fragment>
      {internalState === "pending" && (
        <div className="loader-messages">
          <Loader1 />
        </div>
      )}
      <div
        id="scrollableDiv"
        dir="ltr"
        style={{
          overflow: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          flexBasis: "88%",
          justifyContent: "space-between",
        }}
      >
        <InfiniteScroll
          className="discussion-content"
          dataLength={Messages?.length}
          next={fetchConversationMessages}
          style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
          inverse={true}
          hasMore={
            archived
              ? hasNextPageForArchivedMessages === true
              : hasNextPage === true
          }
          scrollableTarget="scrollableDiv"
        >
          {Messages?.map(
            (item) =>
              !(item?.from === "BOT" && !item?.question) && (
                <Message
                  message={item}
                  key={item._id}
                  user={user}
                  state={state}
                  uniqueKey={item._id}
                  setTodo={setTodo}
                  isTyping={isTyping}
                />
              )
          )}
        </InfiniteScroll>
      </div>
      {isTyping === Messages[0]?.conversation && (
        <div className="is-typing-component">
          <Avatar
            className="avatar-is-typing"
            src={clientConversationStored?.meta?.avatar}
          />
          <span className="is-typing-text">Typing ...</span>
        </div>
      )}
    </React.Fragment>
  ) : (
    (archived ? archivedMessagesStatus : status) === `loading` && (
      <div className="loader-messages">
        <Loader1 />
      </div>
    )
  );
};
export default Messages;
